import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CodepostalService {


  private apiUrl = `${environment.apiUrl}/postal-codes`;

  constructor(private http: HttpClient) { }


  getAllPostalCodes(): Observable<any[]> {
  
    return this.http.get<any[]>(this.apiUrl);
  }


  addPostalCode(postalCodeData: any): Observable<any> {

    return this.http.post<any>(this.apiUrl, { postal_code: postalCodeData });
  }


  updatePostalCode(id: number, postalCodeData: any): Observable<any> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.put<any>(url, postalCodeData);
  }


  deletePostalCode(id: number): Observable<any> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<any>(url);
  }

  updateActiveStatus(id: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${id}/active`, data);
  }
}
